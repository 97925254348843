import { createReducer, on } from '@ngrx/store';
import { addBookmark, loadBookmarks, removeBookmark } from './bookmarks.actions';
import { initialState } from './bookmarks.state';


export const reducer = createReducer(
  initialState,

  on(loadBookmarks, (state, { bookmarks }) => ({
    ...state,
    ...bookmarks
  })),

  on(addBookmark, (state, { bookmark }) => ({
    ...state,
    [bookmark.id]: bookmark
  })),

  on(removeBookmark, (state, { bookmarkId }) => {
    const { [bookmarkId]: bookmark, ...newBookmarks } = state;
    return newBookmarks;
  })
);

