import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ComponentFactoryResolver, Directive, ElementRef, EventEmitter, forwardRef, Host, HostBinding, HostListener, Inject, Input, Optional, Output, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyFormField as MatFormField, MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD } from '@angular/material/legacy-form-field';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { I18N_CONTEXT } from '../../common';
import { WheelPickerComponent } from '../picker/wheel-picker.component';

@Directive({
    selector: 'input[hdisWheelPicker], textarea[hdisWheelPicker]',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => WheelPickerTriggerDirective), multi: true },
    ],
    standalone: true
})
export class WheelPickerTriggerDirective implements ControlValueAccessor {
  @HostBinding('attr.readonly') readonly = true;

  isActive: boolean;

  propagateFn: Function;

  currentValue: any;

  release$: Observable<any>;

  picker: WheelPickerComponent;

  @Input('hdisWheelPicker') target: ViewContainerRef;

  @Input('hdisWheelPickerField') fieldName: string;

  @Input() separator = ', ';

  @Input('readonly')
  get readOnly(): boolean { return this._readOnly; }

  set readOnly(value) { this._readOnly = coerceBooleanProperty(value); }

  _readOnly = false;

  @Input('noI18n')
  get skipI18n(): boolean { return this._skipI18n; }

  set skipI18n(value) { this._skipI18n = coerceBooleanProperty(value); }

  _skipI18n = false;

  @Input('max')
  get maxSelection(): number { return this._maxSelection; }

  set maxSelection(value) { this._maxSelection = coerceNumberProperty(value); }

  _maxSelection: number;

  @Output() opened = new EventEmitter<void>();

  @Output() closed = new EventEmitter<void>();

  constructor(
    private _element: ElementRef<HTMLInputElement>,
    private i18n: TranslocoService,
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
    @Optional() @Inject(MAT_FORM_FIELD) @Host() private _formField: MatFormField,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  writeValue(value: any): void {
    this.currentValue = value;
    if (this.isActive) this.picker.setSelected(this.currentValue);
    this.formatValue();
  }

  private formatValue() {
    let translated = '';

    if (this.currentValue) {
      if (this.skipI18n) {
        translated = Array.isArray(this.currentValue) ? this.currentValue.join(this.separator) : this.currentValue;
      } else if (Array.isArray(this.currentValue)) {
        translated = this.i18n.translate<string[]>(this.currentValue.map((key) => (`${this.i18nContext}${this.fieldName}.values.${key}`))).join(this.separator);
      } else {
        translated = this.i18n.translate<string>(`${this.i18nContext}${this.fieldName}.values.${this.currentValue}`);
      }
    }

    // If it's used within a `MatFormField`, we should set it through the property so it can go
    // through change detection.
    if (this._formField) {
      this._formField._control.value = translated;
    } else {
      this._element.nativeElement.value = translated;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(evt: FocusEvent) {
    if (!this.target) return;

    // empty the editor container from previous components, if any
    this.target.clear();
    // Create enum picker dynamically and append it to the editor container
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WheelPickerComponent);
    const componentRef = this.target.createComponent(componentFactory);
    // save a reference to the enum picker for future use
    this.picker = componentRef.instance;

    this.picker.opened
      .pipe(first())
      .subscribe(() => {
        console.debug(`subscribe to wheel picker events for ${this.fieldName}`);
        this.isActive = true;
        this.opened.emit();
        this.release$ = this.picker.closed
          .pipe(tap(() => {
            console.debug(`release subscriptions for ${this.fieldName}`);
            this.isActive = false;
            this.closed.emit();
          }));
        this.picker.selectionChange
          .pipe(takeUntil(this.release$)).subscribe((newValue) => {
            console.debug(`updated value for ${this.fieldName}`, newValue);
            this.propagateFn(newValue);
            this.writeValue(newValue);
          });
      });

    this.picker.title = this.i18n.translate<string>(`${this.i18nContext}${this.fieldName}`);
    this.picker.multi = this.maxSelection !== 1;
    this.picker.isReadonly = this.readOnly;
    this.picker.open(this.fieldName, this.currentValue);
  }
}
