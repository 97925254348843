import { NgModule } from '@angular/core';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { ChordChartComponent } from './chord-chart/chord-chart.component';
import { QuadrantChartComponent } from './quadrant-chart/quadrant-chart.component';
import { TreemapChartComponent } from './treemap-chart/treemap-chart.component';

@NgModule({
  imports: [
    AreaChartComponent,
    ChordChartComponent,
    QuadrantChartComponent,
    TreemapChartComponent,
  ],
  exports: [
    AreaChartComponent,
    ChordChartComponent,
    QuadrantChartComponent,
    TreemapChartComponent,
  ],
})
export class ChartsModule { }
