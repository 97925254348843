import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MultiUploadComponent } from '../multi-upload/multi-upload.component';

export interface MultiUploadDialogParams {
  title?: string;
  endpoint: string;
}

@Component({
    selector: 'hdis-multi-upload-dialog',
    templateUrl: './multi-upload-dialog.component.html',
    styleUrls: ['./multi-upload-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatLegacyButtonModule, MatIconModule, MultiUploadComponent, FlexModule]
})
export class MultiUploadDialogComponent {
  title: string;

  endpoint: string;

  uploadResponses;

  constructor(
    public dialogRef: MatDialogRef<MultiUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: MultiUploadDialogParams,
  ) {
    this.title = data.title || 'File upload';
    this.endpoint = data.endpoint;
  }

  onUploadCompleted = () => {
    console.log('upload completed');
  };
}
