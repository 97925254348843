import { createFeatureSelector, createSelector } from "@ngrx/store";
import { adapter, PlaylistDayState, featureKey, fromPlaylistDayId, PlaylistDay } from "./playlist-day.reducer";


// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal, } = adapter.getSelectors();


export const selectPlaylistDayState = createFeatureSelector<PlaylistDayState>(featureKey);

export const selectPlaylistDayIds = createSelector(
  selectPlaylistDayState,
  selectIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectPlaylistDayEntities = createSelector(
  selectPlaylistDayState,
  selectEntities
);
export const selectAllPlaylistDays = createSelector(
  selectPlaylistDayState,
  selectAll
);
export const selectPlaylistDayTotal = createSelector(
  selectPlaylistDayState,
  selectTotal
);
export const selectCurrentDayIds = createSelector(
  selectPlaylistDayState,
  state => state.selectedDayIds
)

export const selectClosingTimesVisibility = createSelector(
  selectPlaylistDayState,
  (state) => state.includeClosingTimes
);

export const selectCurrentDays = createSelector(
  selectPlaylistDayEntities,
  selectCurrentDayIds,
  selectClosingTimesVisibility,
  (entities, ids, showClosingTimes) => ids.map(id => {
    // this stinks A LOT as we have some kind of "default entity value" definition in the selector.
    // However at the moment I dont have better ideas how to set a default value when there's no entity yet (i.e. before the effect is executed)
    // so will leave this to a future dev more into ngrx
    if (!entities?.[id]) {
      const { pId, eId, day } = fromPlaylistDayId(id)
      return { pId, eId, day, status: "EMPTY", tracks: [] } as PlaylistDay
    }
    return {
      ...entities[id],
      tracks: entities[id].tracks.filter(track => showClosingTimes || track.ts.length > 0) || []
    }
  })
);

export const selectPlaylistDay = (id: string) => createSelector(
  selectPlaylistDayEntities,
  selectClosingTimesVisibility,
  (entities, showClosingTimes) => {
    if (!entities?.[id]) {
      const { pId, eId, day } = fromPlaylistDayId(id)
      return { pId, eId, day, status: "EMPTY", tracks: [] } as PlaylistDay
    }
    return {
      ...entities[id],
      tracks: entities[id].tracks.filter(track => showClosingTimes || track.ts.length > 0) || []
    }
  }
);

export const selectPlaylistDayTracks = (id: string) => createSelector(
  selectPlaylistDay(id),
  (day) => day.tracks
);

export const selectCurrentDaysIdentifiers = createSelector(
  selectCurrentDayIds,
  (days) => days.map(day => day.split('_').pop())
);

export const selectCurrentDaysTracks = createSelector(
  selectCurrentDays,
  (days) => days.map(day => day.tracks)
);
