import { Bookmark, Bookmarks } from '@heardis/api-contracts';
import { createAction, props } from '@ngrx/store';

export const loadBookmarks = createAction(
  '[Bookmark] Load Bookmarks',
  props<{ bookmarks: Bookmarks }>()
);

export const addBookmark = createAction(
  '[Bookmark] Add Bookmark',
  props<{ bookmark: Bookmark }>()
);

export const removeBookmark = createAction(
  '[Bookmark] Remove Bookmark',
  props<{ bookmarkId: string }>()
);
