// environment.sta.ts
import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  production: true,
  stage: 'staging',
  apiBaseUrl: 'https://sta-api.heardis.com/v1/default',
  assetsBaseUrl: 'https://sta-private.heardis.com',
  streamBaseUrl: 'https://sta-stream.heardis.com',
  onDemandBaseUrl: 'https://sta-play.heardis.com',
  streamWebHostingUrl: 'https://sta-play.heardis.com',
  compilationBaseUrl: 'https://sta-play.heardis.com',
  aws: {
    region: 'eu-west-1',
    cognitoRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_21DlqBRX7',
    userPoolWebClientId: '53dc76g2cfe6lhlh1vpacd1idq',
    identityPoolId: 'eu-central-1:c32ebd9c-d7ad-40f7-9ae1-34d2c1f3ea68',
    schedulesBucketName: 'abcdj-schedules-staging',
    audioBucketName: 'abcdj-audio-files-staging',
    artworkBucketName: 'abcdj-artwork-files-staging',
    plgLogGroupName: '/aws/lambda/plg-staging-generate-playlist'
  }
}
