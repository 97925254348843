import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AudioPlayerProgressCounterComponent } from '../audio-player-progress-counter/audio-player-progress-counter.component';
import { AudioPlayerWaveformComponent } from '../audio-player-waveform/audio-player-waveform.component';
import { AudioPlayerMode, AudioPlayerWaveformViewMode } from '../audio-player.interfaces';
import { AudioPlayerService } from '../audio-player.service';
import { showExtendedWaveform, showStandardWaveform } from '../store/audio-player.actions';
import { selectPlayerViewModel } from '../store/audio-player.selectors';
import { AudioPlayerViewModel } from '../store/audio-player.state';

@Component({
  selector: 'hdis-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  standalone: true,
  imports: [NgIf, MatLegacyButtonModule, MatIconModule, AudioPlayerProgressCounterComponent, AudioPlayerWaveformComponent, AsyncPipe],
})
export class AudioPlayerComponent {
  config$: Observable<AudioPlayerViewModel>;

  AudioPlayerMode = AudioPlayerMode;

  AudioPlayerWaveformViewMode = AudioPlayerWaveformViewMode;

  constructor(
    public playerService: AudioPlayerService,
    private store: Store,
  ) {
    this.config$ = this.store.select(selectPlayerViewModel);
  }

  setExtendedWaveform() {
    this.store.dispatch(showExtendedWaveform());
  }

  setStandardWaveform() {
    this.store.dispatch(showStandardWaveform());
  }
}
