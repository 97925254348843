<div fxLayout="column" fxFlexFill class="wheel-picker-wrapper padded-content">
  <h3 *ngIf="title || showCloseButton">
    <button mat-icon-button *ngIf="showCloseButton" (click)="close()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    {{title}}
  </h3>
  <div fxFlex="auto">
    <canvas #wheel></canvas>
  </div>
</div>
