<div class="flex-column flex-fill">
  <h2>Synchronized lyrics</h2>
  <span *ngIf="!lyrics">Synchronized lyrics not available</span>

  <ng-container *ngIf="lyrics">
    <mat-checkbox color="primary" [checked]="follow" (change)="onFollowChange($event.checked)">karaoke mode</mat-checkbox>

    <div class="flex-auto scrollable-content">
      <ng-container *ngFor="let lrc of lyrics">
        <span *ngIf="lrc.line" [class.follow]="follow" [class.current]="((currentTime >= lrc.milliseconds) && (currentTime < (lrc.milliseconds + lrc.duration)))" (click)="goTo(lrc.milliseconds)">
          {{lrc.timestamp}} {{lrc.line}}
        </span>
      </ng-container>
    </div>
  </ng-container>
</div>
