import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSeverity, roundMs } from '@heardis/hdis-ui';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AudioPlayerTrackStatus, AudioPlayerWaveformViewMode } from '../audio-player.interfaces';
import { AudioPlayerService } from '../audio-player.service';
import { prelisteningDisabled, prelisteningEnabled, setPlaybackRate, setWaveformAutoscroll, setWaveformZoom, snapToGridDisabled, snapToGridEnabled } from '../store/audio-player.actions';
import { AudioPlayerEditorState, AudioPlayerWaveformState } from '../store/audio-player.state';

@Component({
  selector: 'hdis-player-waveform',
  templateUrl: './audio-player-waveform.component.html',
  styleUrls: ['./audio-player-waveform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatSliderModule, MatButtonModule, MatIconModule, MatLegacyProgressBarModule, AsyncPipe],
})
export class AudioPlayerWaveformComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class') get classes() { return this.waveform.mode ? `hdis-player-waveform view-${this.waveform.mode}` : 'hdis-player-waveform'; }

  @Input() trackStatus: AudioPlayerTrackStatus;

  @Input() waveform: AudioPlayerWaveformState;

  @Input() editor: AudioPlayerEditorState;

  @Input() playbackRate: number;

  @ViewChild('waveformEl') waveformContainer: ElementRef;

  @ViewChild('timelineEl') timelineContainer: ElementRef;

  @ViewChild('beatgridEl') beatgridContainer: ElementRef;

  @ViewChild('minimapEl') minimapContainer: ElementRef;

  zoomMin = 1;

  zoomMax = 100;

  zoomStep = 1;

  bufferSize$: Observable<number>;

  private snackbar = inject(MatSnackBar);

  private clipboard = inject(Clipboard);

  private store = inject(Store);

  private playerService = inject(AudioPlayerService);

  AudioPlayerWaveformViewMode = AudioPlayerWaveformViewMode;

  onScroll = (event: WheelEvent) => {
    if (this.waveform.mode === AudioPlayerWaveformViewMode.STANDARD) return;
    if (event.shiftKey) return;

    const { zoom: currentZoom } = this.waveform;
    let targetZoom: number;
    if (event.deltaY < 0) {
      if (currentZoom === this.zoomMax) return;
      // scroll up -> zoom in
      targetZoom = Math.min(this.zoomMax, currentZoom + this.zoomStep);
    } else {
      if (currentZoom === this.zoomMin) return;
      // scroll down -> zoom out
      targetZoom = Math.max(this.zoomMin, currentZoom - this.zoomStep);
    }
    this.changeZoom(targetZoom);
  };

  onKeyDown = (event: KeyboardEvent) => {
    if (this.waveform.mode === AudioPlayerWaveformViewMode.STANDARD) return;
    const { zoom: currentZoom } = this.waveform;
    let targetZoom: number;

    switch (event.code) {
      case 'Digit1':
        // ZOOM IN
        if (currentZoom === this.zoomMax) return;
        targetZoom = Math.min(this.zoomMax, currentZoom + this.zoomStep);
        break;
      case 'Digit2':
        // ZOOM OUT
        if (currentZoom === this.zoomMin) return;
        // scroll down -> zoom out
        targetZoom = Math.max(this.zoomMin, currentZoom - this.zoomStep);
        break;
      case 'Digit3':
        // RESET ZOOM
        targetZoom = this.zoomMin;
        break;
      default:
    }

    this.changeZoom(targetZoom);
  };

  ngAfterViewInit(): void {
    this.playerService.initWaveform(this.waveformContainer.nativeElement, this.timelineContainer.nativeElement, this.beatgridContainer.nativeElement, this.minimapContainer.nativeElement, this.waveform.mode);
    /** @FIXME would be great if (scroll) in the template would work as expected, but no event is fired */
    this.waveformContainer.nativeElement.addEventListener('wheel', this.onScroll);
  }

  ngOnDestroy(): void {
    this.playerService.destroyWaveform();
  }

  changeZoom = (zoom: number) => this.store.dispatch(setWaveformZoom({ zoom }));

  changePlaybackRate = (rate: number) => this.store.dispatch(setPlaybackRate({ rate }));

  setAutoscroll = (autoscroll: boolean) => this.store.dispatch(setWaveformAutoscroll({ autoscroll }));

  setSnapToGrid = (snapToGrid: boolean) => {
    if (snapToGrid) {
      this.store.dispatch(snapToGridEnabled());
    } else {
      this.store.dispatch(snapToGridDisabled());
    }
  };

  setPrelistening = (prelistening: boolean) => {
    if (prelistening) {
      this.store.dispatch(prelisteningEnabled());
    } else {
      this.store.dispatch(prelisteningDisabled());
    }
  };

  copyCursorPositionToClipboard() {
    const currentTime = roundMs(this.playerService.getCurrentTime());
    this.clipboard.copy(`${currentTime}`);
    this.snackbar.open(`copied cursor position to clipboard: ${currentTime}s`, null, { panelClass: [MessageSeverity.INFO] });
  }
}
