<div class="controls">
  <div class="left-controls">
    <button mat-icon-button [matMenuTriggerFor]="queueMenu" [disabled]="!tracklist.hasNext" (click)="setTracklistVisibility(!tracklist.isVisible)">
      <mat-icon>queue_music</mat-icon>
    </button>
    <mat-menu #queueMenu="matMenu" class="playlist-queue">
      <abcdj-audio-player-tracklist [current]="currentTrack" [queue]="tracklist.queue" [next]="tracklist.next"></abcdj-audio-player-tracklist>
    </mat-menu>
  </div>

  <div class="center-controls">
    <button mat-icon-button (click)="previous()" [disabled]="!tracklist.hasPrevious">
      <mat-icon mat-list-icon>skip_previous</mat-icon>
    </button>
    <button mat-icon-button (click)="togglePlayback($event, status)" color="primary" class="play-button" [disabled]="!canInteract">
      <!-- <mat-icon mat-list-icon *ngIf="status === AudioPlayerStatus.EMPTY">pending_outline</mat-icon> -->
      <mat-icon mat-list-icon *ngIf="status === AudioPlayerStatus.EMPTY">pending</mat-icon>
      <mat-icon mat-list-icon *ngIf="status === AudioPlayerStatus.LOADING">downloading</mat-icon>
      <mat-icon mat-list-icon *ngIf="status === AudioPlayerStatus.NOT_PLAYING">play_circle_filled</mat-icon>
      <mat-icon mat-list-icon *ngIf="status === AudioPlayerStatus.PLAYING">pause_circle_filled</mat-icon>
      <mat-icon mat-list-icon *ngIf="status === AudioPlayerStatus.ERROR">sync_problem</mat-icon>
      <mat-icon mat-list-icon *ngIf="status === AudioPlayerStatus.FATAL_ERROR">error</mat-icon>
    </button>
    <button mat-icon-button (click)="next()" [disabled]="!tracklist.hasNext">
      <mat-icon mat-list-icon>skip_next</mat-icon>
    </button>
  </div>

  <div class="right-controls">
    <button mat-icon-button [disabled]="!canToggleWaveform" (click)="setWaveformVisibility(mode === AudioPlayerMode.WIDGET)">
      <mat-icon>graphic_eq</mat-icon>
    </button>
  </div>
</div>
