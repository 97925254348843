import { NgModule } from '@angular/core';
import { BpmFieldComponent } from './bpm-field/bpm-field.component';
import { CssSizeFieldComponent } from './css-size-field/css-size-field.component';
import { SpotifyFormFieldComponent } from './spotify-field/spotify-field.component';
import { StringFieldComponent } from './string-field/string-field.component';
import { TimeFieldComponent } from './time-field/time-field.component';

@NgModule({
  imports: [BpmFieldComponent, CssSizeFieldComponent, StringFieldComponent, TimeFieldComponent, SpotifyFormFieldComponent],
  exports: [BpmFieldComponent, CssSizeFieldComponent, StringFieldComponent, TimeFieldComponent, SpotifyFormFieldComponent],
})
export class FormFieldsModule { }
