import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'relPercent',
    standalone: true
})
export class RelativePercentPipe implements PipeTransform {
  transform(value: number, total: number, suffix?: string, ...args: unknown[]): string | number {
    if (suffix) return `${Math.round((100 * value) / total)}${suffix}`;
    return Math.round((100 * value) / total);
  }
}
