import { Column } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { MatLegacyMenu as MatMenu, MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { HeaderDefinitionsPipe } from '../../pipes/header-definitions.pipe';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf, NgFor } from '@angular/common';

export type HeaderColumn = {
  hasChildren: boolean;
  label: string;
  id: string;
  selected: boolean;
  hasVisibleChildren: boolean;
  hasNotVisibleChildren: boolean;
};

export type ColumnHeaderEvent = { columns: string[]; visibility: boolean };

@Component({
    selector: 'hdis-table-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatLegacyMenuModule, NgIf, MatDividerModule, NgFor, MatPseudoCheckboxModule, forwardRef(() => TableHeaderMenuComponent), HeaderDefinitionsPipe]
})
export class TableHeaderMenuComponent {
  @ViewChild('menu', { static: true }) menu: MatMenu;

  @Input() columns: Column[];

  @Input() branch = '';

  @Output() toggleColumns = new EventEmitter<ColumnHeaderEvent>();

  @Output() resetColumns = new EventEmitter<void>();

  toggle(event, colId: string, selected: boolean) {
    event.preventDefault();
    event.stopPropagation();
    const involvedColumns = this.columns.reduce((cols, col) => {
      if (col.getColId().startsWith(`${this.branch}${colId}`)) cols.add(col.getColId());
      return cols;
    }, new Set<string>());
    this.toggleColumns.emit({ columns: [...involvedColumns], visibility: selected });
  }

  reset(event) {
    event.preventDefault();
    event.stopPropagation();
    this.resetColumns.emit();
  }
}
