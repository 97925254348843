import { NgModule } from '@angular/core';
import { CalendarComponent } from './calendar/calendar.component';

@NgModule({
  exports: [
    CalendarComponent,
  ],
  imports: [
    CalendarComponent,
  ],
})
export class CalendarModule { }
