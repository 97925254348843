import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BookmarksStoreEffects } from './bookmarks.effects';
import { reducer } from './bookmarks.reducer';
import { key } from './bookmarks.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(key, reducer),
    EffectsModule.forFeature([BookmarksStoreEffects])
  ],
  providers: [BookmarksStoreEffects]
})
export class BookmarksStoreModule { }
