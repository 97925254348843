/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable func-names */
import { ElementRef } from '@angular/core';
import { Selection } from 'd3';
import { Dimension } from './chart-interfaces';

export abstract class BaseChart {
  chart: ElementRef;

  svgElement: Selection<SVGElement, any, any, any>;

  tooltipElement: Selection<any, any, any, any>;

  getContainerSize(): Dimension {
    if (this.svgElement) this.svgElement.style('display', 'none');
    const dimension = {
      width: this.chart.nativeElement.clientWidth,
      height: this.chart.nativeElement.clientHeight,
    };
    if (this.svgElement) this.svgElement.style('display', '');

    return dimension;
  }

  protected getMaxRequiredLength(values: (string | number)[]) {
    const textWidths: number[] = [];
    this.svgElement
      .append('g')
      .selectAll('.dummyText')
      .data(values)
      .enter()
      .append('text')
      .text((d) => d)
      .each(function() {
        const thisWidth = (<SVGTextElement> this).getComputedTextLength();
        textWidths.push(thisWidth);
      })
      .remove();
    const maxLength = Math.max(...textWidths) || 0;
    // should use up 1/4 of the chart space at max
    return Math.min(this.getContainerSize().width / 4, maxLength);
  }

  abstract initChart();

  abstract renderChart(size: Dimension);
}
