import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AudioPlayerArtworkComponent } from './audio-player-artwork/audio-player-artwork.component';
import { AudioPlayerKaraokeComponent } from './audio-player-karaoke/audio-player-karaoke.component';
import { AudioPlayerPlaybackControlsComponent } from './audio-player-playback-controls/audio-player-playback-controls.component';
import { AudioPlayerProgressBarComponent } from './audio-player-progress-bar/audio-player-progress-bar.component';
import { AudioPlayerProgressCounterComponent } from './audio-player-progress-counter/audio-player-progress-counter.component';
import { AudioPlayerTrackDetailsComponent } from './audio-player-track-details/audio-player-track-details.component';
import { AudioPlayerTracklistComponent } from './audio-player-tracklist/audio-player-tracklist.component';
import { AudioPlayerWaveformComponent } from './audio-player-waveform/audio-player-waveform.component';
import { AudioPlayerWidgetComponent } from './audio-player-widget/audio-player-widget.component';
import { AudioPlayerComponent } from './component/audio-player.component';

@NgModule({
  imports: [
    AudioPlayerComponent,
    AudioPlayerWaveformComponent,
    AudioPlayerTracklistComponent,
    AudioPlayerArtworkComponent,
    AudioPlayerTrackDetailsComponent,
    AudioPlayerPlaybackControlsComponent,
    AudioPlayerWidgetComponent,
    AudioPlayerProgressCounterComponent,
    AudioPlayerProgressBarComponent,
    AudioPlayerKaraokeComponent,
  ],
  exports: [
    AudioPlayerComponent,
    AudioPlayerWidgetComponent,
    AudioPlayerKaraokeComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AudioPlayerModule { }
