import { NgModule } from '@angular/core';
import { LyricsEditorTriggerDirective } from './directive/lyrics-editor-trigger.directive';
import { LyricsEditorComponent } from './editor/lyrics-editor.component';
import { TimestampLyricsPipe } from './timestamp-lyrics.pipe';

@NgModule({
  imports: [LyricsEditorComponent, LyricsEditorTriggerDirective, TimestampLyricsPipe],
  exports: [LyricsEditorComponent, LyricsEditorTriggerDirective, TimestampLyricsPipe],
})
export class LyricsEditorModule { }
