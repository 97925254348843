import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { axisBottom, axisLeft, scaleLinear, select } from 'd3';
import { BaseChart } from '../base-chart';
import { Dimension } from '../chart-interfaces';
import { QuadrantDataEntry } from './quadrant-chart.interfaces';

@Component({
    selector: 'hdis-quadrant-chart',
    templateUrl: 'quadrant-chart.component.html',
    styleUrls: ['quadrant-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class QuadrantChartComponent extends BaseChart implements AfterViewInit, OnChanges {
  @Input() public data: QuadrantDataEntry[] = [];

  @ViewChild('chartFilter') chartFilter: ElementRef;

  valueNames: string[];

  valueCounts: number[];

  matrix: number[][];

  containerSize: Dimension;

  chartSize: Dimension;

  groupWidth = 10;

  opacity = 0.8;

  maxLabelSize = 100;

  selectedIndex = -1;

  percentThreshold = 0.10;

  constructor(
    private el: ElementRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // first time we wait for afterviewinit to ensure viewchild is bound
    if (changes.data && !changes.data.firstChange) {
      this.initChart();
    }
  }

  ngAfterViewInit() {
    this.initChart();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.renderChart();
  }

  initChart() {
    this.renderChart();
  }

  renderChart() {
    // this.containerSize = {
    //   width: this.el.nativeElement.offsetWidth,
    //   height: this.el.nativeElement.offsetHeight
    // };
    this.containerSize = {
      width: 400,
      height: 400,
    };
    this.chartSize = {
      width: Math.max(this.containerSize.width, this.containerSize.height),
      height: Math.max(this.containerSize.width, this.containerSize.height),
    };
    select('svg.quadrant-chart').remove();
    this.svgElement = select(this.chartFilter.nativeElement)
      .append('svg')
      .attr('class', 'quadrant-chart')
      .attr('width', this.chartSize.width)
      .attr('height', this.chartSize.height)
      .append('g')
      .attr('transform', `translate(${this.chartSize.width / 2}, ${this.chartSize.height / 2})`); // center the chart in the component (would start form top-left corner otherwise)

    // Add chart axes
    const x = scaleLinear().domain([-1, 1]).range([-1, 1]);
    const y = scaleLinear().domain([-1, 1]).range([-1, 1]);
    this.svgElement.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${y.range()[0] / 2})`)
      .call(axisBottom(x).ticks(5));
    this.svgElement.append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${x.range()[1] / 2}, 0)`)
      .call(axisLeft(y).ticks(5));
  }
}
