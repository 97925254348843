import { NgModule } from '@angular/core';
import { AutocompleteComponent } from './autocomplete.component';

@NgModule({
  imports: [
    AutocompleteComponent,
  ],
  exports: [
    AutocompleteComponent,
  ],
})
export class AutocompleteModule { }
