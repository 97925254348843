import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { AreaChartConfig, renderAreaChart } from '@heardis/charts';
import { ComponentResizeEvent } from "../../component-resize";
import { NgIf } from "@angular/common";
import { ComponentResizeDirective } from "../../component-resize/component-resize.directive";

@Component({
    selector: 'hdis-area-chart',
    templateUrl: 'area-chart.component.html',
    styleUrls: ['area-chart.component.scss'],
    standalone: true,
    imports: [ComponentResizeDirective, NgIf]
})
export class AreaChartComponent {

  loading: boolean = false;
  error: boolean = false;

  @Input()
  get config(): AreaChartConfig {
    return this._config
  }
  set config(config: AreaChartConfig) {
    this._config = config;
    this.renderChart('config')
  }
  private _config: AreaChartConfig;

  @ViewChild('chart', { static: true })
  private chartElement: ElementRef;

  private width: number;
  private height: number;

  constructor() { }

  onResize(event: ComponentResizeEvent) {
    // some resizes are triggered for fraction of pixels, we ignore them here.
    // TODO consider moving this to the resize directive
    const newWidth = Math.round(event.current.width)
    const newHeight = Math.round(event.current.height)
    if (newWidth !== this.width || newHeight !== this.height) {
      this.width = newWidth
      this.height = newHeight
      this.renderChart('size')
    }
  }

  renderChart(reason: string) {
    if (!this.config?.data?.length || !this.width || !this.height) return
    console.debug(`render area after ${reason} change`, this.config, this.width, this.height)

    this.loading = true;

    renderAreaChart(this.chartElement.nativeElement, {
      ...this.config,
      width: this.width,
      height: this.height,
    })

    this.loading = false;
  }
}

