import { NgModule } from '@angular/core';
import { AssetPickerFormFieldComponent } from './form-field/asset-picker-form-field.component';
import { AssetPickerComponent } from './picker/asset-picker.component';

@NgModule({
  imports: [
    AssetPickerComponent,
    AssetPickerFormFieldComponent,
  ],
  exports: [
    AssetPickerComponent,
    AssetPickerFormFieldComponent,
  ],
})
export class AssetPickerModule { }
