import { NgModule } from '@angular/core';
import { BytesPipe } from './math/bytes.pipe';
import { RelativePercentPipe } from './math/relative-percent.pipe';
import { WithAuthPipe } from './with-auth/with-auth.pipe';

@NgModule({
  imports: [RelativePercentPipe, BytesPipe, WithAuthPipe],
  exports: [BytesPipe, RelativePercentPipe, WithAuthPipe],
})
export class PipesModule { }
