/* eslint-disable @typescript-eslint/ban-types */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RadioStationLiveStreamTimezone } from '@heardis/api-contracts';
import { Icons, slugify } from '@heardis/hdis-ui';
import { Subscription } from 'rxjs';
import { TimezoneItemComponent } from './timezone-item/timezone-item.component';
import { TimezoneData, timezones } from './timezone-picker.types';

@Component({
  selector: 'hdis-timezone-picker',
  templateUrl: './timezone-picker.component.html',
  styleUrls: ['./timezone-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimezonePickerComponent), multi: true },
  ],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatButtonModule, MatTooltipModule, FlexLayoutModule, TimezoneItemComponent],
})
export class TimezonePickerComponent implements ControlValueAccessor {
  Icons = Icons;

  availableTimezones = timezones;

  timezones: TimezoneData[] = [];

  form: UntypedFormGroup;

  formSub: Subscription;

  isDisabled: boolean;

  propagateFn: Function;

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  writeValue(obj: any): void {
    if (typeof obj === 'string') {
      this.timezones = [this.parseStringTimezone(obj, true)]; // if only one value, this is the default
    } else if (Array.isArray(obj)) {
      this.timezones = obj.map((value, index) => {
        if (typeof value === 'string') {
          // old version, consider default if it is the first
          return this.parseStringTimezone(value, index === 0);
        }
        // new version, timezone is an object containing various settings
        return value as RadioStationLiveStreamTimezone;
      });
    }
    this.form = this.fb.group({
      items: this.fb.array(this.timezones.map((tz) => this.getTimezoneForm(tz))),
    });
    if (this.formSub) this.formSub.unsubscribe();
    this.formSub = this.form.valueChanges.subscribe((newValue) => {
      this.propagateFn?.(newValue.items);
    });
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get timezonesForm() {
    return this.form.get('items') as UntypedFormArray;
  }

  removeTimezone(index: number): void {
    this.timezonesForm.removeAt(index);
  }

  addTimezone() {
    this.timezonesForm.push(this.getTimezoneForm(null));
  }

  parseStringTimezone(code: string, isDefault: boolean): TimezoneData {
    const slug = slugify(code.split('/')[1]);
    return { code, slug, isDefault };
  }

  getTimezoneForm(tzConfig: RadioStationLiveStreamTimezone): UntypedFormGroup {
    return this.fb.group({
      code: tzConfig?.code || '',
      slug: tzConfig?.slug || '',
      isDefault: tzConfig?.isDefault || false,
    });
  }

  toggleFavorite(index: number) {
    this.timezonesForm.controls.forEach((timezoneForm, i) => {
      if (i === index) {
        timezoneForm.get('isDefault').setValue(!timezoneForm.get('isDefault').value);
      } else {
        timezoneForm.get('isDefault').setValue(false);
      }
    });
  }
}
