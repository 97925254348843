import { State, key } from './bookmarks.state';
import { State as AppState } from '../state';
import { createSelector } from '@ngrx/store';

export const selectFeature = (state: AppState) => state[key];

export const getBookmarks = createSelector(
  selectFeature,
  (state: State) => state
);

export const getBookmark = (bookmarkId: string) => createSelector(
  selectFeature,
  (state: State) => state[bookmarkId]
);
