import { Injectable, Injector } from '@angular/core';
import { Playlist, RequestRange, RequestSort, Resource, Schedule } from '@heardis/api-contracts';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Response } from '../_models/response';
import { EntityService } from './entity.service';
import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class ScheduleService extends EntityService<Schedule> {
  baseUrl = `${environment.apiBaseUrl}${environment.endpoints.schedules}`;

  resource = Resource.SCHEDULE;

  entityId = 'schedule';

  constructor(
    injector: Injector,
    private prService: ProfileService,
  ) {
    super(injector);
  }

  getSchedulePlaylists(scheduleId: string, range?: RequestRange, sort?: RequestSort[]): Observable<Response<Playlist>> {
    const url = `${this.baseUrl}/${scheduleId}/_playlists`;
    const queryParams = this.parseQueryParams(range, sort);
    return this.http.get<Playlist[]>(url, { params: queryParams, observe: 'response' })
      .pipe(map(this.handleResponseList), take(1));
  }

  getTableColumns() {
    return super.getTableColumns().pipe(
      map((columns) => columns.map((colDef) => {
        if (colDef.field === 'profileId') {
          return {
            ...colDef,
            type: ['entityColumn'],
            cellRendererParams: {
              fetchFn: this.getProfileLabel,
            },
          };
        } if (colDef.field === 'startDate' || colDef.field === 'endDate') {
          return {
            ...colDef,
            filter: false,
          };
        }
        return colDef;
      })),
    );
  }

  getProfileLabel = (profileId: string): Observable<string> => this.prService.getEntityLabel(profileId);
}

/**
 * Parse string date to Date object ignoring timezone if defined.
 * This is needed for dates defined in schedule timeslots to make them
 * consistent regardless of the timezone of the user browser.
 *
 * This is extra car because dates should already come without Z from the backend
 * But you never know, right?
 * @param strDate RFC-3339 string representation of dates. e.g. 1970-01-01T00:00:00.000Z
 */
const parseDate = (strDate: string): Date =>
  // in 1970-01-01T00:00:00.000Z "Z" is the 24th char and describes UTC
  strDate && new Date(strDate?.slice(0, 23)) || null;
