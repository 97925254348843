import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Lrc } from '@heardis/api-contracts';
import AudioPlayerEventTarget from '../audio-player-event-target';
import { AudioPlayerService } from '../audio-player.service';

@Component({
  selector: 'abcdj-audio-player-karaoke',
  templateUrl: './audio-player-karaoke.component.html',
  styleUrls: ['./audio-player-karaoke.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatCheckboxModule, NgFor],
})
export class AudioPlayerKaraokeComponent implements OnInit {
  @Input() lyrics: Lrc[];

  currentTime: number;

  playerTarget: AudioPlayerEventTarget;

  follow = true;

  constructor(
    public playerService: AudioPlayerService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {
    this.playerTarget = playerService.getTarget();
  }

  ngOnInit(): void {
    this.onFollowChange(this.follow);
  }

  ngOnDestroy(): void {
    // force remove event listener
    this.onFollowChange(false);
  }

  private updateProgress = (event) => {
    this.currentTime = event.detail.progress * 1000;
    this.cdr.markForCheck();
  };

  goTo(timestamp: number) {
    if (!this.follow) return;
    this.playerService.seekTime(timestamp / 1000);
    // explicitly set currentTime because if seek while not playing, the updateProgress wouldnt be triggered
    this.currentTime = timestamp;
    this.cdr.markForCheck();
  }

  onFollowChange(follow) {
    this.follow = follow;
    if (this.follow) {
      this.zone.runOutsideAngular(() => {
        /**
         * apparently there's no need to throttle as the user agent should already take care of this
         * as per MDN docs at https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/timeupdate_event
         */
        this.playerTarget.addEventListener('progress', this.updateProgress);
      });
    } else {
      this.playerTarget.removeEventListener('progress', this.updateProgress);
      this.currentTime = null;
    }
  }
}
