import { NgModule } from '@angular/core';
import { EnumPickerFormFieldComponent } from './form-field/enum-picker-form-field.component';
import { EnumPickerComponent } from './picker/enum-picker.component';
import { EnumPickerTriggerDirective } from './trigger/enum-picker-trigger.directive';

@NgModule({
  imports: [EnumPickerComponent, EnumPickerFormFieldComponent, EnumPickerTriggerDirective],
  exports: [EnumPickerComponent, EnumPickerFormFieldComponent, EnumPickerTriggerDirective],
})
export class EnumPickerModule { }
