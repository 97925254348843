import { Column } from '@ag-grid-community/core';
import { Pipe, PipeTransform } from '@angular/core';
import { HeaderColumn } from '../menus/header/header-menu.component';

@Pipe({
    name: 'headerDefinitions',
    standalone: true
})
export class HeaderDefinitionsPipe implements PipeTransform {
  transform(columns: Column[], branch: string): HeaderColumn[] {
    const tmp = columns.reduce((tmpCols, col) => {
      if (!col.getColId().startsWith(branch)) return tmpCols; // skip anything outside of current branch

      const remainingId = col.getColId().slice(branch.length);
      const colTokens = remainingId.split('.');

      const prevValue = tmpCols.get(colTokens[0]);
      // if (colTokens.length > 1) {
      // skip anything that is grandchildren of current branch (we only need first level children)
      // } else {
      const hasChildren = prevValue?.hasChildren || (colTokens.length > 1);
      const newValue: HeaderColumn = {
        id: colTokens[0],
        label: hasChildren ? colTokens[0] : col.getColDef().headerTooltip || col.getColDef().headerName || colTokens[0],
        hasChildren,
        selected: col.isVisible(),
        hasVisibleChildren: prevValue?.hasVisibleChildren || ((colTokens.length > 1) && col.isVisible()),
        hasNotVisibleChildren: prevValue?.hasNotVisibleChildren || ((colTokens.length > 1) && !col.isVisible()),
      };
      tmpCols.set(colTokens[0], newValue);
      // }
      return tmpCols;
    }, new Map<string, HeaderColumn>());

    return (Array.from(tmp?.values() || []) as Array<HeaderColumn>)
      .sort((a: HeaderColumn, b: HeaderColumn) => {
        if (a.label > b.label) return 1;
        if (b.label > a.label) return -1;
        return 0;
      });
  }
}
