import { ClipboardModule } from '@angular/cdk/clipboard';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MAT_LEGACY_CHIPS_DEFAULT_OPTIONS as MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/legacy-chips';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Amplify } from '@aws-amplify/core';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { HearDisUiModule, MissingTranslationHandler, UserPreferenceProvider } from '@heardis/hdis-ui';
import { TRANSLOCO_MISSING_HANDLER } from '@ngneat/transloco';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { NotAuthorizedComponent } from './_global/not-authorized/not-authorized.component';
import { NotFoundComponent } from './_global/not-found/not-found.component';
import { ServiceUnavailableComponent } from './_global/service-unavailable/service-unavailable.component';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { AppGridLayoutComponent } from './_layouts/app-grid-layout/app-grid-layout.component';
import { AuthenticatedComponent } from './_layouts/authenticated/authenticated.component';
import { ErrorLayoutComponent } from './_layouts/error-layout/error-layout.component';
import { DateAdapter as AbcdjDateAdapter } from './_services/date.adapter';
import { SongFormService } from './_services/song-form.service';
import { SongService, TRACK_METADATA_PROVIDER } from './_services/song.service';
import { UserService } from './_services/user.service';
import { RootStoreModule } from './_state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AudioPlayerModule } from './audio-player';
import { StatusChangesGuard } from './guard/status-changes.guard';
import { TranslocoRootModule } from './i18n/transloco-root.module';

// import aws_exports from "./aws-exports";
Amplify.configure({
  Auth: { // see https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
    identityPoolId: environment.aws.identityPoolId,
    region: environment.aws.cognitoRegion,
    userPoolId: environment.aws.userPoolId,
    userPoolWebClientId: environment.aws.userPoolWebClientId,
    // tested many times, still cant send the copy to private.heardis.com requests... and without that it's useless
    // cookieStorage: {
    //   // - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'heardis.com',
    //   // // (optional) - Cookie path
    //   // path: '/',
    //   // // (optional) - Cookie expiration in days
    //   // expires: 365,
    //   // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'none',
    //   // (optional) - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },
  },
});
Amplify.Logger.LOG_LEVEL = 'DEBUG';

@NgModule({
  declarations: [
    AppComponent,
    ErrorLayoutComponent,
    AppGridLayoutComponent,
    NotFoundComponent,
    NotAuthorizedComponent,
    ServiceUnavailableComponent,
    AuthenticatedComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    ClipboardModule,
    FlexLayoutModule,
    OverlayModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    HearDisUiModule,
    AmplifyAuthenticatorModule,
    // OAuthModule.forRoot({
    //   // add http-interceptor for requests to the backend which adds the necessary Authorization header.
    //   resourceServer: {
    //     allowedUrls: [environment.apiBaseUrl],
    //     sendAccessToken: true
    //   }
    // }),
    AudioPlayerModule,
    TranslocoRootModule,
    RootStoreModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    SongFormService,
    StatusChangesGuard,
    { provide: UserPreferenceProvider, useExisting: UserService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: AbcdjDateAdapter,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: '600px',
        panelClass: 'hdis-dialog-container',
        hasBackdrop: true,
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
      } as MatSnackBarConfig,
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: MissingTranslationHandler,
    },
    {
      provide: TRACK_METADATA_PROVIDER,
      useExisting: SongService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
