import { createAction, props } from '@ngrx/store';
import { Update, EntityMap, EntityMapOne, Predicate } from '@ngrx/entity';
import { PlaylistDay } from './playlist-day.reducer';
import { PlaylistTrack } from '@heardis/api-contracts';

/** actions inherited from @ngrx/entity */
export const loadPlaylistDays = createAction('[Playlist/API] Load Days', props<{ days: PlaylistDay[] }>());
export const setPlaylistDays = createAction('[Playlist/API] Set Days', props<{ days: PlaylistDay[] }>());
export const addPlaylistDay = createAction('[Playlist/API] Add Day', props<{ day: PlaylistDay }>());
export const setPlaylistDay = createAction('[Playlist/API] Set Day', props<{ day: PlaylistDay }>());
export const upsertPlaylistDay = createAction('[Playlist/API] Upsert Day', props<{ day: PlaylistDay }>());
export const addPlaylistDays = createAction('[Playlist/API] Add Days', props<{ days: PlaylistDay[] }>());
export const upsertPlaylistDays = createAction('[Playlist/API] Upsert Days', props<{ days: PlaylistDay[] }>());
export const updatePlaylistDay = createAction('[Playlist/API] Update Day', props<{ update: Update<PlaylistDay> }>());
export const updatePlaylistDays = createAction('[Playlist/API] Update Days', props<{ updates: Update<PlaylistDay>[] }>());
export const mapPlaylistDay = createAction('[Playlist/API] Map Day', props<{ entityMap: EntityMapOne<PlaylistDay> }>());
export const mapPlaylistDays = createAction('[Playlist/API] Map Days', props<{ entityMap: EntityMap<PlaylistDay> }>());
export const deletePlaylistDay = createAction('[Playlist/API] Delete Day', props<{ id: string }>());
export const deletePlaylistDays = createAction('[Playlist/API] Delete Days', props<{ ids: string[] }>());
export const deletePlaylistDaysByPredicate = createAction('[Playlist/API] Delete Days By Predicate', props<{ predicate: Predicate<PlaylistDay> }>());
export const clearPlaylistDays = createAction('[Playlist/API] Clear Days');

/** actions to integrate with the backend */
export const fetchPlaylistDay = createAction('[Playlist/API] Fetch Day', props<{ id: string }>());
export const fetchPlaylistDaySuccess = createAction('[Playlist/API] Fetch Day Success', props<{ id: string, tracks: PlaylistTrack[] }>());
export const fetchPlaylistDayFailure = createAction('[Playlist/API] Fetch Day Failed', props<{ id: string, error: any }>());

/** actions for user interaction */
export const selectPlaylistDays = createAction('[Playlist/Page] Select Days', props<{ ids: string[] }>());

export const toggleClosingTimesVisibility = createAction('[Playlist/Page] Toggle closing times visibility')
export const setClosingTimesVisibility = createAction('[Playlist/Page] Set closing times visibility', props<{ visible: boolean }>())
