import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ModalsModule } from './modals/modals.module';

@NgModule({
  imports: [
    ModalsModule,
  ],
  exports: [
  ],
  declarations: [
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: '600px',
        panelClass: 'hdis-dialog-container',
        hasBackdrop: true,
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'right',
      } as MatSnackBarConfig,
    },
  ],
})
export class HearDisUiModule { }
