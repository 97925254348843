import { Injectable, Injector } from '@angular/core';
import { Audio, Pool, PoolActionRequest, PoolTracksRequestBody, PoolType, Query, RequestProps, RequestRange, RequestSort, Resource, TrackExportRequest } from '@heardis/api-contracts';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Response } from '../_models/response';
import { AudioPlayerTrack } from '../audio-player';
import { EntityService } from './entity.service';
import { SongService } from './song.service';

@Injectable({ providedIn: 'root' })
export class PoolService extends EntityService<Pool> {
  baseUrl = environment.apiBaseUrl + environment.endpoints.pools;

  resource = Resource.POOL;

  entityId = 'pool';

  constructor(
    injector: Injector,
    private sService: SongService,
  ) {
    super(injector);
  }

  getPoolSongs(poolId: string, overwrite: Query, filter: Query, range?: RequestRange, sort?: RequestSort[], props?: RequestProps): Observable<Response<Audio>> {
    const requestBody: PoolTracksRequestBody = {
      ...(filter ? { filter } : {}),
      ...(overwrite ? { definition: overwrite } : {}),
      ...(props ? { ...props } : {}),
    };
    return this.searchListInternal<Audio>(`${this.baseUrl}/${poolId}/_songs`, requestBody, range, sort);
  }

  getPoolPlayerTracks(poolId: string, range?: RequestRange, sort?: RequestSort[]): Observable<AudioPlayerTrack[]> {
    const requestBody: PoolTracksRequestBody = {
      projection: 'player',
    };
    return this.searchListInternal<Audio>(`${this.baseUrl}/${poolId}/_songs`, requestBody, range, sort).pipe(
      map((response) => response.content),
      map((tracks) => tracks.map(this.sService.mapToAudioPlayerTrack)),
    );
  }

  changePoolType(pool: Pool, mode: PoolType): Observable<any> {
    return this.partialUpdateEntity(pool._id, pool.rev, {
      type: mode,
    });
  }

  getTermFilter(term: string): Query {
    return {
      or: [
        { regex: { name: term.toLowerCase() } },
      ],
    } as Query;
  }

  listManager(poolId: string, songsIds: string[], type: string): any {
    const url = `${this.baseUrl}/${poolId}/${type}`;
    return this.http.put(url, songsIds);
  }

  getAvaibleProfiles(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiBaseUrl}/maintenance/export/availableProfiles`)
      .pipe(take(1));
  }

  getTableColumns() {
    return super.getTableColumns();
  }

  exportPoolSongs(poolId: string, format: string): Observable<string> {
    return this.http.get<{ url: string }>(`${this.baseUrl}/${poolId}/_songs/_export`, { params: { format } })
      .pipe(map((data) => data.url));
  }

  requestAction(docId: string, payload: PoolActionRequest): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${docId}/_action`, payload).pipe(take(1));
  }

  requestTracksExportAction(docId: string, payload: TrackExportRequest): Observable<string> {
    return this.http.post<{ url: string }>(`${this.baseUrl}/${docId}/_action`, payload).pipe(
      map((data) => data.url),
      take(1),
    );
  }
}
