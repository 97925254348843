import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Store } from '@ngrx/store';
import { initUser, resetUser } from '../../_state/user/user.actions';

// DISCLAIMER: This is the stupidest component we have. The only reason it exists is because amplify library support to angular just sucks
// We need to listen to user events and store user info in ngrx user state. This would allow the UserState effects to load user view preferences
// and other stuff. Unfortunately no reasonable solution is available as amplify does not expose any observable, does not trigger all the
// events it should and does not invoke functions that used to invoke.

// Option 1: listen to auth events and dispatch actions according to the event type. Unfortunately when loading the webapp as a previously
// authenticated user, no event is triggered. So we could not read the user info without going through the login flow.
// Hub.listen(AUTH_CHANNEL, (data) => {
//   console.debug(`[auth] ${data.payload.event}`, data.payload)
//   switch (data.payload.event) {
//     case 'signIn':
//       // const accessToken = (authData as any)?.signInUserSession?.accessToken;
//       // this.store.dispatch(initUser({
//       //   username: accessToken.payload.username,
//       //   jwt: accessToken.jwtToken,
//       //   roles: accessToken.payload['cognito:groups']
//       // }))
//       break;
//     case 'signOut':
//       this.store.dispatch(resetUser())
//       break;
//   }
// });

// Option 2: define a callback for onAuthUIStateChange, and dispatch actions according to authState. Unfortunately after updating to amplify@4
// this function is not called anymore
// onAuthUIStateChange((authState, authData) => {
//   console.log('[auth] auth state changed', authState, authData)
//   this.authState = authState;
//   this.user = authData as CognitoUserInterface;
//   // this.authService.setToken((authData as any)?.signInUserSession?.accessToken?.payload || null);
//   // this.user = authData as CognitoUserInterface;
//   if (authState === AuthState.SignedIn) {
//     const accessToken = (authData as any)?.signInUserSession?.accessToken;
//     this.store.dispatch(initUser({
//       username: accessToken.payload.username,
//       jwt: accessToken.jwtToken,
//       roles: accessToken.payload['cognito:groups']
//     }))
//   } else {
//     // this.user = null
//     this.store.dispatch(resetUser())
//   }
//   this.ref.detectChanges();
// })

// Option 3: this component that is rendered as the main content by the amplify components in app.component.ts. onInit we read the user, onDestroy we reset
@Component({
  selector: 'abcdj-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticatedComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private authenticator: AuthenticatorService,
  ) { }

  ngOnInit(): void {
    try {
      console.log('[authenticated] parse user info', this.authenticator.user);
      const accessToken = this.authenticator.user.getSignInUserSession().getAccessToken();
      const { username, 'cognito:groups': roles } = accessToken.payload;
      // const user = this.authenticator.user;
      this.store.dispatch(initUser({ username, roles }));
    } catch (err) {
      console.error('[authenticated] something really weird happend', err);
    }
  }

  ngOnDestroy(): void {
    console.log('[authenticated] reset user info');
    this.store.dispatch(resetUser());
  }
}
